<template>
  <div>
    <div class="Limits_wrapper">
      <div class="Limits_wrapperTitle">Limits and Fees</div>
      <LimitsTable />
      <div class="Limits_wrapperInfo">
        <div class="Limits_wrapperTitle">Account Limits</div>
        <div class="Limits_wrapperSubTitle">Verification tier 1</div>
        <div class="Limits_wrapperItem">Deposit/Withdrawl limits: <span>up to 15 000 EUR</span> per year</div>
        <div class="Limits_wrapperItem">
          Pass online verification - proof of identity, address, liveness selfie,
          <a target="_blank" href="/docs/Individuals_questionnaire.pdf">questionnaire</a>
        </div>
        <div class="Limits_wrapperDivider"></div>
        <div class="Limits_wrapperSubTitle">Verification tier 2</div>
        <div class="Limits_wrapperItem">Deposit/Withdrawl limits: <span>15 000 EUR - 100 000 EUR</span> per year</div>
        <div class="Limits_wrapperItem">
          To get the increased limits and all the benefits -
          <a target="_blank" href="/docs/funds_origin_RUS_EST_ENG.pdf">present the proof of funds</a>
        </div>
        <div class="Limits_wrapperDivider"></div>
        <div class="Limits_wrapperSubTitle">Verification tier 3</div>
        <div class="Limits_wrapperItem">Deposit/Withdrawl limits: more than <span>100 000 EUR</span> per year</div>
        <div class="Limits_wrapperItem">Pass face to face verification at the company’s offices (Tallinn, Limassol, Kyiv)</div>
      </div>
    </div>
  </div>
</template>

<script>
import LimitsTable from '@/components/_common/Limits/Limits';
export default {
  components: {
    LimitsTable,
  },
};
</script>

<style lang="scss" scoped>
.Limits_wrapperInfo {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Limits_wrapperItem {
  font-size: 16px;
  line-height: 170%;
  color: #fff;
  span {
    font-weight: bold;
    color: #2af3f3;
  }
  a {
    text-decoration: underline;
    color: #2af3f3;
  }
}
.Limits_wrapperDivider {
  width: 100%;
  height: 1px;
  background: #2af3f3;
  opacity: 0.1;
  margin: 30px 0;
}
.Limits_wrapperSubTitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  &::before {
    content: '';
    width: 10px;
    height: 8px;
    background-image: url('../../../assets/img/landing/titleIcon.svg');
    display: block;
    background-position: center;
    background-size: cover;
    margin: 0 6px 0 0;
  }
}
.Limits_wrapper {
  position: relative;
  overflow: hidden;
  padding: 140px 0 120px 0;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
  &Title {
    color: #2af3f3;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.04em;
    font-weight: bold;
    text-align: left;
    margin: 0 0 18px 0;
  }
}
</style>
